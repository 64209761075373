/*
 * CB Stepper
 */

.cb-stepper-input {
  position: relative;
  max-width: fit-content;

  input {
    z-index: 1;

    width: 110px;
    max-width: 110px;
    padding-right: 40px !important;
    padding-left: 40px !important;

    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  &.cb-stepper-input-3 {
    input {
      width: 120px;
      max-width: 120px;
    }
  }

  &.cb-stepper-input-4 {
    input {
      width: 130px;
      max-width: 130px;
    }
  }

  &.cb-stepper-input-unset {
    input {
      width: unset;
      max-width: unset;
    }
  }

  .cb-btn {
    position: absolute;
    z-index: 2;
    top: 8px;

    width: 2em;
    height: 2em;
    padding: 0;

    font-size: 1rem;
    line-height: 1em;

    border: transparent;

    &:first-of-type {
      left: 8px;
    }

    &:last-of-type {
      right: 8px;
    }

    .cb-icon {
      position: relative;
      top: 0;
      left: 0;

      padding: 0;

      color: $gray1;

      transition: color $transition-time-l ease;
    }

    &:hover {
      background: transparent;
      box-shadow: none;
    }

    &:disabled {
      &:hover {
        background-color: $gray5;
        border-color: $gray5;
      }
    }

    &:focus {
      background: transparent;
      box-shadow: none;

      .cb-icon {
        color: $blue5;
      }
    }
  }

  .cb-validation-label-input {
    width: auto;
  }

  &.cb-input-condensed,
  &.cb-condensed {
    .cb-btn {
      width: 1.5em;
      height: 1.5em;

      .cb-icon {
        font-size: $font-size-small !important;
      }
    }
  }

  &.cb-disabled {
    .cb-btn {
      background: transparent;

      .cb-icon {
        color: $gray2;
      }
    }
  }
}

label {
  &.cb-condensed {
    font-size: $font-size-small;
    line-height: 1.7143em;
  }

  &.cb-disabled {
    color: $gray2 !important;
  }
}
