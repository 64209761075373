/*
 * CB Menu List Mixins
 */

@mixin horizontal-menu-link($condensed: 0) {
  display: flex;
  align-items: flex-end;

  @if $condensed == 0 {
    padding: 0 24px 12px;
  }

  @if $condensed == 1 {
    padding: 0 8px 8px;
  }

  height: 100%;

  &.cb-condensed {
    padding: 0 8px 8px;
  }

  &.cb-selected {
    box-shadow: inset 0 -3px 0 -1px $black1;
  }

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 -3px 0 -1px $black1;
  }
}

@mixin vertical-menu-link() {
  display: block;
  width: 100%;
  padding: 16px 24px;

  &.cb-selected {
    box-shadow: inset 2px 0 0 0 $black1;
  }

  &:hover,
  &.cb-hover {
    box-shadow: inset 2px 0 0 0 $black1;
  }
}
