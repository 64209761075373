/*
 * CB Horizontal Carousel
 */

@import 'apricot-variable';
@import '../mixins/breakpoint';

.cb-horizontal-carousel {
  /* ------------------------------------ OPTION 2 */

  $dark-bg: rgb(255 255 255 / 5%);
  $light-bg: $gray5;

  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;

  .cb-horizontal-carousel-nav {
    position: relative;
    overflow: auto hidden;

    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: -ms-autohiding-scrollbar;

    @include media-breakpoint-up('sm', $grid-breakpoints) {
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .cb-horizontal-carousel-content {
      float: left;
      transition: transform $transition-time-s ease-in-out;

      &.cb-no-transition {
        transition: none;
      }
    }
  }

  .cb-horizontal-nav {
    cursor: default;

    position: absolute;
    z-index: 1;
    top: 0;

    height: 100%;

    text-align: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 100%;
      padding: 6px 0;

      font-size: 0.75rem;
      color: $white;
      text-decoration: none;

      opacity: 0.6;
      background-color: $black1;

      transition: all $transition-time;

      .cb-glyph,
      .cb-icon {
        font-size: 1rem;
        line-height: 1.5em;
        transition: all $transition-time ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        width: 32px;
        text-decoration: none;
        opacity: 0.8;

        .cb-glyph,
        .cb-icon {
          font-size: 1.5rem;
          line-height: 1em;
        }
      }
    }

    &.cb-horizontal-nav-right {
      right: 0;

      &[data-cb-overflow='both'],
      &[data-overflow='right'] {
        opacity: 1;
      }
    }

    &.cb-horizontal-nav-left {
      left: 0;

      &[data-cb-overflow='both'],
      &[data-overflow='left'] {
        opacity: 1;
      }
    }
  }

  &:not(.cb-horizontal-carousel-menu),
  &:not(.cb-horizontal-carousel-light),
  &:not(.cb-horizontal-carousel-dark) {
    .cb-horizontal-nav {
      opacity: 0;
      transition: opacity $transition-time ease-in-out;
    }
  }

  &[data-cb-overflow='both'],
  &[data-cb-overflow='right'] {
    .cb-horizontal-nav {
      &.cb-horizontal-nav-right {
        opacity: 1;

        a {
          cursor: pointer;
        }
      }
    }
  }

  &[data-cb-overflow='both'],
  &[data-cb-overflow='left'] {
    .cb-horizontal-nav {
      &.cb-horizontal-nav-left {
        opacity: 1;

        a {
          cursor: pointer;
        }
      }
    }
  }

  /* IE issue */
  .cb-event-cards-horizontal {
    &.cb-ie-fix {
      > ol {
        > li {
          min-width: 352px;
          min-height: 304px;
        }
      }
    }
  }

  &.cb-horizontal-carousel-menu,
  &.cb-horizontal-carousel-menu-condensed,
  &.cb-horizontal-carousel-light,
  &.cb-horizontal-carousel-dark {
    ul {
      padding-top: 1px;

      li {
        a {
          white-space: nowrap;
        }
      }
    }

    .cb-horizontal-carousel-nav {
      overflow-x: auto;
      max-width: 100%;
    }

    .cb-horizontal-nav {
      width: 48px;
      opacity: 1 !important;

      a {
        cursor: default;

        display: flex;

        width: 48px;

        font-size: 1.5rem;

        opacity: 1;

        .cb-glyph,
        .cb-icon {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1em;
        }

        &:hover,
        &:active,
        &:focus {
          color: inherit;

          .cb-glyph,
          .cb-icon {
            font-size: 1.5rem;
            font-weight: 900;
            line-height: 1em;
            opacity: 0.8;
          }
        }
      }

      &.cb-horizontal-nav-right {
        animation: $transition-time slideout-right forwards;
      }

      &.cb-horizontal-nav-left {
        animation: $transition-time slideout-left forwards;
      }
    }

    &[data-cb-overflow='both'],
    &[data-cb-overflow='right'] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          clip-path: inset(0 -3px 0 -8px);
          border-left: 1px solid $light-bg;
          box-shadow: -5px 0 11px -5px rgb(0 0 0 / 10%);

          transition: box-shadow $transition-time ease-in-out;
          animation: $transition-time slidein-right;

          &:hover,
          &:focus,
          &:active {
            box-shadow: -5px 0 11px -5px rgb(0 0 0 / 20%);
          }
        }
      }
    }

    &[data-cb-overflow='both'],
    &[data-cb-overflow='left'] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-left {
          clip-path: inset(0 -8px 0 0);
          border-right: 1px solid $light-bg;
          box-shadow: 5px 0 11px -5px rgb(0 0 0 / 10%);

          transition: box-shadow $transition-time ease-in-out;
          animation: $transition-time slidein-left;

          &:hover,
          &:focus,
          &:active {
            box-shadow: 5px 0 11px -5px rgb(0 0 0 / 20%);
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-dark {
    &[data-cb-overflow='both'],
    &[data-cb-overflow='right'] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          border-left: 1px solid $dark-bg;
          box-shadow: -5px 0 11px -5px rgb(0 0 0 / 20%);

          &:hover,
          &:focus,
          &:active {
            box-shadow: -5px 0 11px -5px rgb(0 0 0 / 30%);
          }
        }
      }
    }

    &[data-cb-overflow='both'],
    &[data-cb-overflow='left'] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-left {
          border-right: 1px solid $dark-bg;
          box-shadow: 5px 0 11px -5px rgb(0 0 0 / 20%);

          &:hover,
          &:focus,
          &:active {
            box-shadow: 5px 0 11px -5px rgb(0 0 0 / 30%);
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-menu,
  &.cb-horizontal-carousel-menu-condensed {
    .cb-horizontal-carousel-nav {
      height: 72px;

      &::-webkit-scrollbar {
        display: block !important;
        -webkit-appearance: none;
        appearance: none;

        &:horizontal {
          height: 4px;
        }
      }

      &::-webkit-scrollbar-thumb {
        /* should match background, can't be transparent */
        background-color: $gray4;
        border: 0;
        border-radius: 8px;
      }

      .cb-tabs-menu,
      .cb-menu-list {
        ul {
          li {
            margin-bottom: 4px;
          }
        }
      }
    }

    .cb-horizontal-nav {
      a {
        font-size: 1rem;
        line-height: 1.5em;

        .cb-glyph,
        .cb-icon {
          font-size: 1rem;
          line-height: 1.5em;
        }

        &:hover,
        &:focus,
        &:active {
          .cb-glyph,
          .cb-icon {
            font-size: 1rem;
            line-height: 1.5em;
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-menu-condensed {
    .cb-horizontal-carousel-nav {
      height: 48px;
    }
  }
}

@include media-breakpoint-only('xs', $grid-breakpoints) {
  $dark-bg: rgb(255 255 255 / 5%);
  $light-bg: $gray5;

  .cb-horizontal-carousel {
    &.cb-horizontal-carousel-menu,
    &.cb-horizontal-carousel-light,
    &.cb-horizontal-carousel-dark {
      transition: box-shadow $transition-time ease-in-out;

      .cb-horizontal-carousel-nav {
        &::-webkit-scrollbar {
          display: block !important;
          -webkit-appearance: none;
          appearance: none;

          &:horizontal {
            height: 4px;
          }
        }

        &::-webkit-scrollbar-thumb {
          /* should match background, can't be transparent */
          background-color: $gray4;
          border: 0;
          border-radius: 8px;
        }
      }

      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          right: -16px;
        }

        &.cb-horizontal-nav-left {
          left: -16px;
        }

        a {
          display: none;
        }
      }

      &[data-cb-overflow='both'],
      &[data-cb-overflow='right'] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-right {
            width: 16px;
            clip-path: inset(0 -3px 0 -8px);
            border-left: 1px solid $light-bg;
            box-shadow: -5px 0 11px -5px rgb(0 0 0 / 20%);
          }
        }
      }

      &[data-cb-overflow='both'],
      &[data-cb-overflow='left'] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-left {
            width: 16px;
            clip-path: inset(0 -8px 0 0);
            border-right: 1px solid $light-bg;
            box-shadow: 5px 0 11px -5px rgb(0 0 0 / 20%);
          }
        }
      }
    }

    &.cb-horizontal-carousel-dark {
      &[data-cb-overflow='both'],
      &[data-cb-overflow='right'] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-right {
            border-left: 1px solid $dark-bg !important;
            box-shadow: -5px 0 11px -5px rgb(0 0 0 / 30%);
          }
        }
      }

      &[data-cb-overflow='both'],
      &[data-cb-overflow='left'] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-left {
            border-right: 1px solid $dark-bg !important;
            box-shadow: 5px 0 11px -5px rgb(0 0 0 / 30%);
          }
        }
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .cb-horizontal-carousel-menu,
  .cb-horizontal-carousel-light,
  .cb-horizontal-carousel-dark {
    .cb-horizontal-nav {
      a {
        &:hover,
        &:focus,
        &:active {
          outline: none !important;

          .cb-glyph,
          .cb-icon {
            position: relative;

            &::after {
              content: '';

              position: absolute;
              top: -12px;
              left: -12px;

              display: block;

              width: calc(100% + 24px);
              height: calc(100% + 24px);

              border-radius: inherit;
              box-shadow: inset 0 0 0 2px $blue5;
            }
          }
        }
      }
    }

    &.cb-horizontal-carousel-dark {
      .cb-horizontal-nav {
        a {
          &:hover,
          &:focus,
          &:active {
            .cb-glyph,
            .cb-icon {
              &::after {
                box-shadow: inset 0 0 0 2px $yellow1;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slidein-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideout-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideout-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
