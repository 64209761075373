/*
 * CB Spacer
 */

/* ------------------------------------ HORIZONTAL */
.cb-spacerh,
spacerh {
  display: inline-block;
  width: #{$spacer-h}px;
}

@include spacer('h', $spacers-h);
@include custom-spacer('h', $spacer-h);

/* ------------------------------------ VERTICAL */
.cb-spacerv,
spacerv {
  display: block;
  height: #{$spacer-v}px;
}

@include spacer('v', $spacers-v);
@include custom-spacer('v', $spacer-v);
