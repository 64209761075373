/*
 * CB Event Timeline
 */

@import 'apricot-variable';
@import '../mixins/event-timeline';
@import '../mixins/breakpoint';

.cb-event-timeline {
  ol,
  .row {
    display: flex;
    padding: 0;

    .cb-card {
      display: flex;
      flex-direction: column;

      .cb-event-date {
        display: flex;
        flex-direction: column;

        .cb-event-year {
          font-size: 1.4375rem;
          font-weight: 700;
          line-height: 24px;

          &.cb-event-year-repeat {
            height: 1px;
            padding: 0 !important;
            color: transparent;
          }
        }

        .cb-date-time-tags {
          margin-bottom: 12px;
        }
      }

      .cb-event-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .cb-event-title {
          position: relative;

          margin-top: 12px;

          font-size: 1.3125rem;
          font-weight: 500;
          line-height: 1.1429em;
        }

        .cb-event-desc {
          margin-top: 8px;
        }

        .cb-meta-tags {
          padding-top: 16px;
          text-transform: uppercase;
        }
      }
    }
  }

  &.cb-event-cards-horizontal {
    @include event-cards-horizontal();

    div[class*='col-'] {
      .cb-card {
        width: auto;
        min-width: auto;
        height: calc(100% - 72px);
      }
    }
  }

  &.cb-event-cards-vertical {
    @include event-cards-vertical();
  }
}

@include media-breakpoint-only('xs', $grid-breakpoints) {
  .cb-horizontal-carousel {
    .cb-event-cards-horizontal {
      ol,
      .row {
        padding: 0 8px !important;
      }
    }
  }
}
