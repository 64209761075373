/*
 * CB Filter
 */

@import 'apricot-variable';
@import '../mixins/breakpoint';

.cb-filter {
  &:not(.cb-glyph):not(.cb-icon) {
    min-width: 400px;

    .cb-popover-inner {
      padding-bottom: 16px;
    }

    &.cb-popover-content-adjust {
      overflow: hidden;
      max-height: calc(100vh - 96px);

      .cb-popover-content {
        overflow-x: hidden;
        padding: 2px 2px 0;
      }
    }
  }
}
