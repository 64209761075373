/*
 * CB Input Mask
 */

@import 'apricot-variable';

.cb-input-mask,
.cb-input-date {
  &.cb-floating-label {
    .cb-input-icon-right {
      label {
        justify-content: flex-start;
        width: calc(100% - 50px);
      }
    }

    input {
      &::placeholder {
        color: transparent;
        transition: color $transition-time-l ease;
      }
    }

    .cb-value-fl,
    .cb-focus-fl {
      justify-content: center;
      width: auto;

      + input {
        &::placeholder {
          color: $input-placeholder;
        }
      }
    }

    .cb-input-icon-right {
      .cb-value-fl,
      .cb-focus-fl {
        justify-content: center;
        width: auto;
      }
    }
  }
}
