/*
 * CB Dropdown
 */

@import 'apricot-variable';
@import '../mixins/font';
@import '../mixins/breakpoint';
@import '../mixins/link';

.cb-dropdown {
  position: relative;

  a {
    /* black */
    @include black-link();
  }

  > a {
    display: inline-flex;
    align-items: flex-end;
    height: 48px;
    padding: 0 24px 12px;

    &:hover,
    &:active,
    &:focus {
      box-shadow: inset 0 -3px 0 -1px $black1;
    }

    &:focus {
      outline: 0;
    }
  }

  &.cb-open {
    > a {
      box-shadow: inset 0 -3px 0 -1px $black1;

      &:focus {
        outline: 0;
      }
    }
  }

  &.cb-disabled {
    > a {
      color: $gray3;

      &:hover,
      &:focus,
      &:active {
        cursor: not-allowed;
        color: $gray3;
        box-shadow: none;
      }
    }
  }

  &.cb-dropdown-condensed {
    height: 40px;
  }

  .cb-dropdown-toggle {
    font-size: $font-size-small !important;
    line-height: $line-height-small-16;
    text-align: center;
    text-decoration: none !important;

    transition: background-color $transition-time-s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    .cb-glyph,
    .cb-icon {
      margin-right: 8px;

      &.cb-down,
      &.cb-up {
        margin-right: 0;
        margin-left: 8px;
        font-size: 0.75rem;
      }
    }
  }

  .cb-dropdown-menu {
    position: absolute;
    z-index: 899;
    top: 100%;
    left: 0;

    display: none;

    min-width: 100%;

    font-size: $font-size-small;
    line-height: $line-height-small-16;

    background-color: $white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @include roboto();

    &.cb-menu-right,
    &.cb-menu-right-always {
      right: 0;
      left: auto;
    }

    .cb-input-container {
      margin-bottom: 12px;
      padding: 24px 24px 16px;
      border-bottom: 1px solid $gray4;

      .cb-input-helper {
        margin-top: 8px;
      }
    }

    /* ul */
    ul {
      padding: 12px 0;
      list-style: none;

      ul {
        padding: 0;
      }

      > li {
        > a {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;
          padding: 12px 24px;

          font-size: $font-size-small;
          line-height: $line-height-small-16;
          text-decoration: none;
          white-space: nowrap;

          transition: background-color $transition-time-s ease-in-out;
          transition: box-shadow $transition-time-s ease-in-out;

          @include media-breakpoint-down('sm', $grid-breakpoints) {
            padding: 16px 24px;
          }

          &:focus {
            text-decoration: underline;
          }

          &:hover,
          &:active {
            text-decoration: none;
            background-color: $gray5;
            box-shadow: inset 2px 0 0 0 $black1;
          }

          &:focus[aria-disabled='true'] {
            box-shadow: inset 0 0 0 2px $gray4;
          }

          .cb-glyph,
          .cb-icon {
            margin-left: 8px;
            color: $gray3;
          }

          &.cb-selected {
            box-shadow: inset 2px 0 0 0 $black1;
          }
        }

        &.cb-disabled {
          > a {
            &:hover,
            &:active {
              box-shadow: none;
            }
          }
        }
      }

      &.cb-dropdown-left-icon {
        > li {
          > a {
            justify-content: flex-start;

            .cb-glyph,
            .cb-icon {
              margin-right: 8px;
              margin-left: 0;
            }
          }
        }
      }
    }

    .cb-input-container {
      + ul {
        padding-top: 0;
      }
    }

    li {
      &.cb-dropdown-header {
        padding: 4px 24px;

        p,
        h3,
        h4,
        h5 {
          font-size: $font-size-x-small;
          font-weight: 700;
          line-height: 1.3333em;
          color: $gray2;
        }
      }

      &.cb-disabled {
        a {
          color: $gray3;

          &:hover,
          &:focus,
          &:active {
            cursor: not-allowed;
            color: $gray3;
          }
        }
      }

      &.cb-divider {
        overflow: hidden;
        height: 1px;
        margin: 12px 0;
        border-top: 1px solid $gray4;
      }

      input {
        &:focus {
          outline: 0;
        }
      }
    }
  }

  &.cb-open {
    .cb-dropdown-menu {
      display: block;

      &.cb-dropdown-menu-scroll {
        overflow-y: auto;
        min-height: 64px;

        @include media-breakpoint-down('sm', $grid-breakpoints) {
          min-height: 72px;
        }
      }
    }
  }
}

/* Custom Select Dropdown */
.cb-select {
  .cb-dropdown {
    width: 100%;

    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 3rem;
      padding: 11px 15px;

      text-align: left;

      background-color: $white;
      border: 1px solid $gray4;
      border-radius: $border-radius-input;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }

      &:focus:not([aria-disabled='true']) {
        border-color: $blue5;
        box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
      }

      &[aria-disabled='true'] {
        cursor: not-allowed;

        > span {
          color: $gray2;
        }
      }

      > span {
        overflow: hidden;
        flex: 1;

        font-size: 1rem;
        line-height: 1.5em;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .cb-icon {
        margin: 0;
        font-weight: 700;
        line-height: 1.5em;
      }
    }

    &.cb-open {
      > button {
        border-color: $blue5;
        box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);

        &:focus {
          outline: 0;
        }
      }
    }

    .cb-dropdown-menu {
      max-width: 100%;
      box-shadow:
        0 2px 5px -3px rgb(0 0 0 / 20%),
        0 3px 10px 1px rgb(0 0 0 / 10%);

      li {
        a,
        .cb-dropdown-header {
          padding: 4px 15px;
          white-space: wrap;
        }
      }

      ul[role='group'] {
        > li {
          a {
            padding: 4px 24px;
          }
        }
      }
    }
  }

  &.cb-condensed {
    .cb-dropdown {
      > button {
        height: 2.5rem;
        padding: 7px 15px;

        > span {
          font-size: $font-size-small;
          line-height: 1.7143em;
        }
      }
    }
  }

  &.cb-validation-error {
    .cb-dropdown {
      > button {
        border-color: $red1;

        &:focus:not([aria-disabled='true']) {
          border-color: $red1;
          box-shadow: inset 0 0 1px 0.5px $error-color;
        }
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .cb-dropdown {
    .cb-dropdown-menu {
      /* ul */
      ul {
        > li {
          > a {
            &:focus {
              text-decoration: none !important;
              outline: 0;
              box-shadow: inset 0 0 0 2px $outline-color !important;
            }

            &:focus[aria-disabled='true'] {
              text-decoration: none !important;
              outline: 0;
              box-shadow: inset 0 0 0 2px $outline-color;
            }
          }
        }
      }
    }
  }

  .cb-select {
    .cb-dropdown {
      > button {
        &:focus:not([aria-disabled='true']) {
          border-color: $blue5;
          outline: 0;
          box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
        }
      }
    }
  }
}
