/*
 * CB Utility Mixins
 */

@use 'sass:string';

@mixin namespace($parent: false, $namespace: $apricot-namespace) {
  @if $namespace {
    @if $parent {
      &.#{$namespace} {
        @content;
      }
    } @else {
      .#{$namespace} {
        @content;
      }
    }
  } @else {
    @content;
  }
}

@mixin border-box() {
  box-sizing: border-box;
}

@mixin cb-no-focus() {
  outline: none !important;
}

@mixin no() {
  display: none !important;
}

/* Replace `$search` with `$replace` in `$string`
 * @author Hugo Giraudel
 * @param {String} $string - Initial string
 * @param {String} $search - Substring to replace
 * @param {String} $replace ('') - New value
 * @return {String} - Updated string
 */
@function str-replace($string, $search, $replace: '') {
  $index: string.index($string, $search);

  @if $index {
    @return string.slice($string, 1, $index - 1) + $replace + str-replace(string.slice($string, $index + string.length($search)), $search, $replace);
  }

  @return $string;
}
