/*
 * CB Notification
 */

@import 'apricot-variable';

.cb-notification {
  border: 1px solid $border-color;

  .cb-notification-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;

    .cb-notification-header {
      display: flex;
      align-items: center;
      min-height: 30px;

      > .cb-glyph,
      > .cb-icon {
        flex-shrink: 0;
        margin-right: 16px;
      }

      .cb-btn-close {
        margin-left: auto;
      }
    }

    .cb-notification-title {
      /* 21 */
      font-size: 1.3125rem;
      font-weight: 400;

      /* 24 */
      line-height: 1.1429em;
    }

    .cb-notification-content {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      padding-left: 48px;

      .cb-btn-row {
        margin-top: 24px;
      }
    }

    .cb-notification-header {
      + .cb-notification-content {
        margin-top: 12px;
      }
    }
  }

  & {
    height: auto;
    transition: height $transition-time-l ease;
  }

  &.cb-notification-hide {
    overflow: hidden;
    height: 0;
    padding: 0;
    border: 0;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
