/*
 * CB Forms Base
 */

@import 'apricot-variable';
@import '../mixins/font';
@import '../mixins/utils';
@import '../mixins/glyph';
@import '../mixins/form';
@import '../mixins/breakpoint';
@import '../mixins/link';

/* ------------------------------------ INPUT, TEXTAREA */

.cb-input {
  > div {
    width: 100%;
  }

  input,
  textarea {
    display: block;

    width: 100%;
    padding: 11px 15px;

    line-height: 1.5em;

    background-color: $white;
    border: 1px solid $gray4;
    border-radius: $border-radius-input;

    @include border-box();

    &::placeholder {
      font-size: 1rem;
      line-height: 1.5;
      color: $input-placeholder;
    }

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
      }

      &:focus {
        cursor: default;
        border: 1px solid $input-focus;
        outline: 0;
        box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: $gray2;
      -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */

      -webkit-text-fill-color: $gray2; /* Override iOS / Android font color change */
    }
  }

  &.cb-disabled {
    label,
    .cb-glyph,
    .cb-icon {
      color: $gray2 !important;
    }
  }

  .cb-glyph,
  .cb-icon {
    font-size: 1em;
  }

  .cb-input-icon-right,
  .cb-input-icon-left {
    position: relative;

    .cb-glyph,
    .cb-icon {
      position: absolute;
      top: 36px;
      padding: 4px 0;
      color: $gray1;
    }
  }

  .cb-input-icon-left {
    input {
      padding-left: 39px;
    }

    .cb-glyph,
    .cb-icon {
      left: 16px;
    }
  }

  .cb-input-icon-right {
    input {
      padding-right: 40px;
    }

    .cb-glyph,
    .cb-icon {
      right: 16px;
    }
  }

  &.cb-input-no-label {
    .cb-input-icon-left,
    .cb-input-icon-right {
      .cb-glyph,
      .cb-icon {
        top: 12px;
      }
    }
  }

  /* ------------------------------------ BUTTON INPUT */
  &.cb-btn-input {
    position: relative;

    input {
      z-index: 1;
      padding-right: 48px;
    }

    .cb-btn {
      position: absolute;
      z-index: 2;
      top: 32px;
      right: 8px;

      width: 2em;
      height: 2em;
      padding: 0;

      font-size: 1rem;
      line-height: 1em;

      border: transparent;

      .cb-glyph,
      .cb-icon {
        position: relative;
        top: 0;
        left: 0;

        padding: 0;

        color: $gray1;

        transition: color $transition-time-l ease;
      }

      &:hover {
        background: transparent;
        box-shadow: none;
      }

      &:disabled {
        &:hover {
          background-color: $gray5;
          border-color: $gray5;
        }
      }

      &:focus {
        background: transparent;
        box-shadow: none;

        .cb-glyph,
        .cb-icon {
          color: $blue5;
        }
      }
    }

    input[aria-label] + .cb-btn {
      top: 8px;
    }

    &.cb-password-input {
      .cb-btn {
        &:focus {
          .cb-glyph,
          .cb-icon {
            color: $black1;
          }
        }
      }
    }

    &.cb-input-condensed,
    &.cb-condensed {
      .cb-btn {
        width: 1.5em;
        height: 1.5em;

        .cb-glyph,
        .cb-icon {
          font-size: $font-size-small !important;
        }
      }
    }

    &.cb-input-no-label {
      .cb-btn {
        top: 8px;
      }
    }

    &.cb-disabled {
      .cb-btn {
        background: transparent;

        .cb-glyph,
        .cb-icon {
          color: $gray2;
        }
      }
    }
  }

  /* ------------------------------------ CONDENSED */
  &.cb-input-condensed,
  &.cb-condensed {
    font-size: $font-size-small;
    line-height: 1.7143em;

    input,
    textarea {
      padding: 7px 15px;

      &::placeholder {
        font-size: $font-size-small;
        line-height: 1.7143em;
      }
    }

    .cb-input-icon-right,
    .cb-input-icon-left {
      .cb-glyph,
      .cb-icon {
        top: 33px;
      }
    }

    .cb-input-icon-left {
      input {
        padding-left: 39px;
      }

      .cb-glyph,
      .cb-icon {
        left: 16px;
      }
    }

    .cb-input-icon-right {
      input {
        padding-right: 40px;
      }

      .cb-glyph,
      .cb-icon {
        right: 16px;
      }
    }

    &.cb-input-no-label {
      .cb-input-icon-left,
      .cb-input-icon-right {
        .cb-glyph,
        .cb-icon {
          top: 10px;
        }
      }
    }

    &.cb-btn-input {
      input {
        padding-right: 38px;
      }

      .cb-btn {
        .cb-glyph,
        .cb-icon {
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

/* ------------------------------------ HELPER TEXT */
.cb-input-helper {
  margin-top: 8px;
  font-size: $font-size-x-small;
  line-height: 1.3333em;
  color: $gray1;

  &.cb-validation-error {
    color: $red1;
  }
}

.cb-validation-error,
.cb-validation-success {
  + .cb-input-helper {
    /* 2.5em: width of icon */
    width: calc(100% - 2.5em);
  }
}

/* ------------------------------------ RADIO +  CHECKBOX */

/* ------------------------------------ radio */
.cb-radio {
  display: flex;

  label {
    cursor: pointer;
    align-items: flex-start;
  }

  input[type='radio'] {
    position: relative;

    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-top: 1px;
    margin-right: 8px;
    margin-left: 1px;

    &::before {
      cursor: pointer;
      content: '';

      position: absolute;
      top: -1px;
      left: -1px;

      box-sizing: border-box;
      width: 24px;
      min-width: 24px;
      height: 24px;

      background-color: $white;
      border: 1px solid $black1;
      border-radius: 50%;
      outline: none;

      transition: all $transition-time-s ease-out;
    }

    &::after {
      content: '';

      position: absolute;
      top: 11px;
      left: 11px;

      display: block;

      box-sizing: border-box;
      width: 0;
      height: 0;

      opacity: 1;
      background-color: $blue5;
      border: 0 solid $blue5;
      border-radius: 50%;

      transition: all $transition-time ease-out;
    }

    &:checked {
      &::before {
        background-color: $white;
        border-color: $blue5;
        border-width: 2px;
      }

      &::after {
        top: 4px;
        left: 4px;

        width: 0;
        height: 0;

        border: 7px solid $blue5;
      }
    }

    &:hover:not(:checked),
    &:focus:not(:checked) {
      &::before {
        background: $gray5;
        border: 1px solid $blue5;
      }
    }
  }

  .cb-label {
    margin-left: 8px;
  }

  &.cb-condensed {
    input[type='radio'] {
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-top: 5px;
      margin-right: 4px;

      &::before {
        top: 0;
        left: -1px;

        width: 16px;
        min-width: 16px;
        height: 16px;
      }

      &::after {
        top: 8px;
        left: 8px;
      }

      &:checked {
        &::after {
          top: 4px;
          left: 3px;
          border: 4px solid $blue5;
        }
      }
    }

    .cb-label {
      margin-left: 4px;
    }
  }
}

/* ------------------------------------ checkbox */
.cb-checkbox {
  display: flex;

  label {
    cursor: pointer;
    align-items: flex-start;
  }

  input[type='checkbox'] {
    position: relative;

    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-top: 1px;
    margin-right: 8px;
    margin-left: 1px;

    &::before {
      cursor: pointer;
      content: '';

      position: absolute;
      top: -1px;
      left: -1px;

      width: 24px;
      min-width: 24px;
      height: 24px;

      background-color: $white;
      border: 1px solid $black1;
      border-radius: 4px;
      outline: none;

      transition: all $transition-time-s ease-out;
    }

    &::after {
      content: '';

      position: absolute;
      top: 12px;
      left: 8px;
      transform: rotate(0deg) scale(0);

      width: 0;
      height: 0;

      opacity: 1;
      border: solid $white;
      border-width: 0 3px 3px 0;
      border-radius: 4px;

      transition: all $transition-time ease-out;
    }

    &:checked {
      &::before {
        background-color: $blue5;
        border-color: $blue5;
      }

      &::after {
        top: 3px;
        left: 8px;
        transform: rotate(45deg) scale(1);

        width: 6px;
        height: 12px;

        opacity: 1;
        background-color: transparent;
        border: solid $white;
        border-width: 0 2px 2px 0;
        border-radius: 0;
      }
    }

    &:hover:not(:checked),
    &:focus:not(:checked) {
      outline: none;

      &::before {
        background: $gray5;
        border: 1px solid $blue5;
      }
    }
  }

  .cb-label {
    margin-left: 9px;
  }

  &.cb-condensed {
    input[type='checkbox'] {
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-top: 5px;
      margin-right: 4px;

      &::before {
        top: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
      }

      &:checked {
        &::after {
          top: 2px;
          left: 5px;

          width: 4px;
          height: 9px;

          border-width: 0 1px 1px 0;
        }
      }
    }

    .cb-label {
      margin-left: 5px;
    }
  }

  &.cb-checkbox-nested {
    &.cb-checkbox-mixed {
      input[type='checkbox'] {
        &::before {
          background-color: $blue5 !important;
          border-color: $blue5 !important;
        }

        &::after {
          top: 3px;
          transform: rotate(90deg) scale(1);

          width: 6px;
          height: 12px;

          background-color: transparent;
          border: solid $white;
          border-width: 0 2px 0 0;
          border-radius: 0;

          transition: all 0.1s ease-out;
        }
      }
    }

    input[type='checkbox'] {
      &:checked {
        &::after {
          transform: rotate(45deg) scale(1);
          opacity: 1;
          border-width: 0 2px 2px 0;
        }
      }
    }

    &.cb-condensed {
      &.cb-checkbox-mixed {
        input[type='checkbox'] {
          &::after {
            top: 2px;
            left: 5px;

            width: 4px;
            height: 9px;

            border-width: 0 1px 0 0;
          }
        }
      }

      input[type='checkbox'] {
        &:checked {
          &::after {
            transform: rotate(45deg) scale(1);
            border-width: 0 1px 1px 0;
          }
        }
      }
    }
  }
}

/* ------------------------------------ disabled */
.cb-radio,
.cb-checkbox {
  &.cb-disabled,
  &.disabled,
  fieldset[disabled] & {
    label {
      color: $cb-disabled-font-color;
    }

    input {
      &::before {
        cursor: not-allowed !important;
        background: $gray5 !important;
        border: 1px solid $gray4 !important;
      }
    }

    input[type='checkbox'] {
      &:checked {
        &::after {
          border-color: $gray4;
        }
      }
    }

    input[type='radio'] {
      &:checked {
        &::after {
          top: 4px;
          left: 4px;
          background: $gray4;
          border-color: $gray4;
        }
      }
    }
  }
}

/* ------------------------------------ Firefox */
/* stylelint-disable-next-line at-rule-no-vendor-prefix */
@-moz-document url-prefix() {
  .cb-radio,
  .cb-checkbox {
    input {
      appearance: none;
    }
  }
}

/* ------------------------------------ helper text */
.cb-checkbox,
.cb-radio {
  + .cb-input-helper {
    margin-top: 8px;
  }
}

/* ------------------------------------ TABLE */
.cb-table:not(.cb-input-default) {
  td {
    .cb-input {
      input {
        padding: 0 6px;
        font-size: $font-size-small;
        background: $gray4;
      }

      .cb-btn {
        top: 3px !important;
        width: 1.1em;
        height: 1.1em;

        .cb-glyph,
        .cb-icon {
          font-size: $font-size-small !important;
          line-height: 1em !important;
        }
      }

      &.cb-floating-label {
        margin-top: 2px;

        label {
          top: 2px;
          font-size: $font-size-small;
          background: transparent;

          &.cb-value-fl,
          &.cb-focus-fl {
            top: -14px;
            left: 0;
            font-size: $font-size-x-small;
          }
        }
      }
    }
  }
}

/* ------------------------------------ LEGEND */
legend {
  padding: 0;

  /* 26 */
  font-size: 1.625rem;

  /* 32 */
  line-height: 1.2308em;

  &.cb-label {
    font-size: $font-size-small;
    font-weight: 500;
    line-height: $line-height-small;
    color: $gray1;
  }
}

/* ------------------------------------ TOGGLE BUTTON   */

.cb-toggles-btn {
  display: inline-flex;
  font-size: 0;

  label {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;

    @include border-box();

    &:hover {
      cursor: pointer;
    }

    input[type='radio'] {
      position: absolute;
      top: 10px;
      left: 10px;

      margin: 0;
      padding: 0;

      + span:not(.cb-glyph):not(.cb-icon) {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 48px;
        height: 48px;

        font-size: 0.75rem;
        color: $black1;

        background-color: $white;
        border-top: 1px solid $gray4;
        border-bottom: 1px solid $gray4;

        @include roboto-bold();

        & + .cb-glyph,
        & + .cb-icon {
          z-index: 3;
          width: 1em;
          height: 1em;
          font-size: 1rem;
        }
      }

      &:hover,
      &:focus,
      &:checked,
      &.cb-checked {
        outline: 0;
        box-shadow: none;
      }

      &:hover {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray5;
        }
      }

      &:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $white;
          border: 1px solid $black1;
        }
      }

      &:active:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray3;
          border: 1px solid $gray4;
        }
      }

      &:checked {
        & + span:not(.cb-glyph):not(.cb-icon) {
          text-decoration: underline;
          background-color: $gray4;

          & + .cb-glyph,
          & + .cb-icon {
            text-decoration: underline;
          }
        }
      }

      /* matching secondary disabled button style */
      &[disabled],
      &.disabled,
      fieldset[disabled] & {
        & + span:not(.cb-glyph):not(.cb-icon) {
          cursor: not-allowed;
          color: $gray4;
          background-color: $gray5;

          + .cb-glyph,
          + .cb-icon {
            cursor: not-allowed;
            color: $gray4;
          }
        }
      }
    }

    &:first-of-type {
      input[type='radio'] {
        & + span:not(.cb-glyph):not(.cb-icon) {
          border-left: 1px solid $gray4;
          border-top-left-radius: $btn-border-radius-input;
          border-bottom-left-radius: $btn-border-radius-input;
        }

        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            border-left: 1px solid $black1;
          }
        }
      }
    }

    &:last-of-type {
      input[type='radio'] {
        & + span:not(.cb-glyph):not(.cb-icon) {
          border-right: 1px solid $gray4;
          border-top-right-radius: $btn-border-radius-input;
          border-bottom-right-radius: $btn-border-radius-input;
        }

        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            border-right: 1px solid $black1;
          }
        }
      }
    }
  }
}

/* ------------------------------------ TOGGLE SWITCH */

.cb-toggle-switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  .cb-switch {
    position: relative;
    display: inline-block;
    outline: none;

    .cb-switch-track {
      box-sizing: border-box;
      width: 48px;
      height: 24px;

      background: $gray2;
      border: 1px solid;
      border-color: $gray2;
      border-radius: 999px;

      transition:
        opacity 90ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .cb-switch-wrap {
      position: absolute;
      top: -12px;
      right: auto;
      left: -12px;
      transform: translateX(0);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 48px;
      height: 48px;

      transition:
        transform 90ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);

      .cb-switch-thumb {
        z-index: 1;

        box-sizing: border-box;
        width: 20px;
        height: 20px;

        border: 11px solid $white;
        border-radius: 50%;
      }
    }

    &.cb-checked {
      .cb-switch-track {
        background: $blue5;
        border-color: $blue5;
      }

      .cb-switch-wrap {
        transform: translateX(24px);
      }
    }

    &.cb-disabled {
      .cb-switch-track {
        background: $gray4;
        border-color: $gray4;
      }

      .cb-switch-wrap {
        .cb-switch-thumb {
          background: $gray5;
        }
      }

      + label {
        color: $cb-disabled-font-color;
      }

      input {
        cursor: not-allowed;
      }
    }

    &:hover:not(.cb-disabled) {
      .cb-switch-track {
        background: $gray1;
        border-color: $gray1;
      }

      .cb-switch-wrap {
        .cb-switch-thumb {
          background: $gray5;
        }
      }
    }

    &.cb-checked {
      &:hover:not(.cb-disabled) {
        .cb-switch-track {
          background: $blue5-shade1;
          border-color: $blue5-shade1;
        }

        .cb-switch-wrap {
          .cb-switch-thumb {
            background: $gray5;
          }
        }
      }
    }

    input {
      cursor: pointer;

      position: absolute;
      top: 0;
      right: auto;
      left: 0;

      width: 68px;
      height: 48px;
      margin: 0;

      opacity: 0;
    }
  }

  label {
    cursor: pointer;

    & + .cb-switch {
      margin-left: 8px;
    }
  }

  .cb-switch {
    & + label {
      margin-left: 8px;
    }
  }

  &.cb-condensed {
    .cb-switch {
      .cb-switch-track {
        width: 32px;
        height: 16px;
      }

      .cb-switch-wrap {
        top: -8px;
        left: -8px;
        width: 32px;
        height: 32px;

        .cb-switch-thumb {
          width: 14px;
          height: 14px;
          border: 7px solid $white;
        }
      }

      &.cb-checked {
        .cb-switch-wrap {
          transform: translateX(16px);
        }
      }

      input {
        top: 4px;
        left: 0;
        width: 32px;
        height: 24px;
      }
    }

    label {
      & + .cb-switch {
        margin-left: 4px;
      }
    }

    .cb-switch {
      & + label {
        margin-left: 4px;
      }
    }
  }
}

/* ------------------------------------ REQUIRED */
.cb-required {
  &::after {
    content: '*';
    margin-left: 3px;
    font-size: 1rem;
  }
}

/* ------------------------------------ DROPDOWN INPUT */
.cb-input-dropdown {
  border-radius: $btn-border-radius-input;

  .cb-dropdown-input {
    position: relative;

    .cb-dropdown-menu {
      a {
        /* black */
        @include black-link();
      }

      & {
        z-index: 899;
        top: 0;
        left: 0;

        display: none;

        min-width: 100%;
        max-width: 100%;

        font-size: $font-size-small;
        line-height: $line-height-small;

        background-color: $white;

        @include roboto();
      }

      /* ul */
      ul {
        padding: 0 0 12px;
        list-style: none;

        > li {
          > a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            padding: 12px 24px;

            font-size: $font-size-small;
            line-height: $line-height-small-16;
            text-decoration: none !important;

            transition: all $transition-time-s ease-in-out;

            @include media-breakpoint-down('sm', $grid-breakpoints) {
              padding: 16px 24px;
              white-space: normal;
            }

            &:hover,
            &:active {
              background-color: $gray5;
              box-shadow: inset 2px 0 0 0 $black1;
            }

            .cb-glyph,
            .cb-icon {
              margin-left: 8px;
              color: $gray3;
            }
          }
        }

        &.cb-dropdown-left-icon {
          > li {
            a {
              justify-content: flex-start;

              .cb-glyph,
              .cb-icon {
                margin-right: 8px;
                margin-left: 0;
              }
            }
          }
        }
      }

      li {
        &.cb-dropdown-header {
          padding: 4px 24px;

          h3,
          h4,
          h5 {
            font-size: $font-size-x-small;
            font-weight: 700;
            line-height: 1.3333em;
            color: $gray2;
          }

          .cb-no-suggestion {
            padding: 8px 0;
            font-size: $font-size-small;
            line-height: $line-height-base;
            color: $gray2;
          }
        }

        &.cb-disabled {
          a {
            color: $gray3;

            &:hover,
            &:focus,
            &:active {
              cursor: not-allowed;
              color: $gray3;
            }
          }
        }

        &.cb-divider {
          overflow: hidden;

          height: 1px;
          margin: 12px 0;
          padding: 0;

          background-color: $gray4;
        }

        input {
          &:focus {
            outline: 0;
          }
        }
      }
    }

    .cb-dropdown-container {
      position: relative;

      &.cb-has-dropdown-overlay {
        position: absolute;
        z-index: 899;
        top: -9px;

        min-width: 100%;
        max-width: 100%;
      }

      .cb-dropdown-helper {
        position: absolute;
        z-index: 900;
        right: 0;
        bottom: 0;
        left: 0;
        transform-origin: bottom;
        transform: scaleY(0);

        padding: 8px 16px;

        font-size: $font-size-small;
        line-height: $line-height-small;
        text-align: center;

        border-bottom-right-radius: $border-radius-input;
        border-bottom-left-radius: $border-radius-input;

        transition-timing-function: ease-in-out;
        transition-duration: 0s;
      }
    }
  }

  &.cb-input {
    &.cb-open {
      input {
        border-color: transparent;
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
      }

      .cb-dropdown-menu {
        position: relative;

        display: block;

        border: 1px solid transparent;
        border-top: 0;
        border-bottom-right-radius: $border-radius-input;
        border-bottom-left-radius: $border-radius-input;
      }

      .cb-dropdown-container {
        .cb-dropdown-helper {
          &.cb-show {
            transform: scaleY(1);
            background-color: $white;
            border-top: 1px solid $gray4;
            transition-duration: $transition-time-s;
          }
        }
      }

      .cb-input-icon-right {
        .cb-glyph,
        .cb-icon {
          &:not(.cb-x-mark) {
            display: none;
          }
        }
      }
    }

    &.cb-floating-label {
      label {
        &:not(.cb-value-fl),
        &:not(.cb-focus-fl) {
          justify-content: start;
          width: calc(100% - 32px);
        }

        &.cb-value-fl,
        &.cb-focus-fl {
          justify-content: center;
          width: auto;
        }
      }

      .cb-input-icon-left,
      .cb-input-icon-right {
        label {
          &:not(.cb-value-fl),
          &:not(.cb-focus-fl) {
            justify-content: start;
            width: calc(100% - 48px);
          }

          &.cb-value-fl,
          &.cb-focus-fl {
            justify-content: center;
            width: auto;
          }
        }
      }
    }
  }

  &.cb-open {
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  }

  .cb-btn {
    top: 8px !important;
  }

  &.cb-input-dropdown-overlay {
    &.cb-input {
      &.cb-open {
        .cb-dropdown-menu {
          position: absolute;
          top: -9px;
          box-shadow: 0 9px 8px 0 rgb(0 0 0 / 20%);
        }

        .cb-has-dropdown-overlay {
          background-color: $white;
          border-bottom-right-radius: $border-radius-input;
          border-bottom-left-radius: $border-radius-input;
          box-shadow: 0 9px 8px 0 rgb(0 0 0 / 20%);

          .cb-dropdown-menu {
            top: 0;
            box-shadow: none;
          }
        }
      }
    }
  }

  &.cb-input-dropdown-scroll {
    .cb-dropdown-input {
      .cb-dropdown-menu {
        overflow-y: auto;
      }
    }
  }
}

.cb-input-dropdown-overflow {
  position: relative;
  width: 100%;
  min-height: 48px;

  .cb-input-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  /* ------------------------------------ toggle switch */
  .cb-toggle-switch {
    .cb-switch {
      &:focus,
      &.cb-focus {
        .cb-switch-track {
          background: $gray2;
          border-color: $white;
          box-shadow: 0 0 0 2px $blue5;
        }

        .cb-switch-wrap {
          .cb-switch-thumb {
            background: $white;
            border-color: $white;
          }
        }
      }

      &.cb-checked {
        &:focus,
        &.cb-focus {
          .cb-switch-track {
            background: $blue5;
            border-color: $white;
            box-shadow: 0 0 0 2px $blue5;
          }

          .cb-switch-wrap {
            .cb-switch-thumb {
              background: $gray5;
              border-color: $white;
            }
          }
        }
      }
    }
  }

  /* ------------------------------------ radio */
  .cb-radio {
    input[type='radio'] {
      &:focus {
        outline: none;

        &::before {
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }

  /* ------------------------------------ checkbox */
  .cb-checkbox {
    input[type='checkbox'] {
      &:focus {
        outline: none;

        &::before {
          border: 1px solid $white;
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }

  /* ------------------------------------ toggle button */

  .cb-toggles-btn {
    input[type='radio'] {
      &:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          box-shadow: inset 0 0 1px 2px $gray1;
        }
      }
    }
  }

  /* ------------------------------------ input button */
  .cb-btn-input:not(.cb-clear-input):not(.cb-password-input) {
    .cb-btn {
      &:focus {
        outline: -webkit-focus-ring-color auto 5px;
        box-shadow: none;
      }
    }
  }

  /* ------------------------------------ password input */

  .cb-btn-input {
    &.cb-password-input {
      .cb-btn {
        &:focus {
          box-shadow: none;

          .cb-glyph,
          .cb-icon {
            font-weight: 900;
            color: $black1;
          }
        }
      }
    }
  }

  /* ------------------------------------ input dropdown */
  .cb-input-dropdown {
    .cb-dropdown-input {
      .cb-dropdown-menu {
        /* ul */
        ul {
          > li {
            > a {
              &:focus {
                outline: 0;
                box-shadow: inset 0 0 0 2px $blue5;
              }

              &:focus[aria-disabled='true'] {
                outline: 0;
                box-shadow: inset 0 0 0 2px $gray4;
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------ label left */
.cb-label-left {
  margin-left: -8px;
  padding: 8px;
  background: transparent;

  &.cb-toggle-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    label {
      flex-grow: 1;
      justify-content: start;
    }
  }

  &.cb-radio,
  &.cb-checkbox {
    label {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &.cb-required {
        input {
          margin-left: auto;
        }
      }
    }

    .cb-label {
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    background: $gray5;
    border-radius: 4px;
    transition: all $transition-time-s ease-in-out;
  }
}

/* ------------------------------------ IE 10, 11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cb-btn-input {
    .cb-btn {
      display: none !important;
    }
  }
}
