/*
 * CB Links
 */

@import '../mixins/link';

a,
.cb-link-blue,
.cb-link-default {
  @include default-link();
}

a {
  /* black */
  &.cb-link-black {
    @include black-link();
  }

  /* white */
  &.cb-link-white {
    @include white-link();
  }
}

.cb-link-black {
  @include black-link();
}

.cb-link-dotted {
  display: inline;
  width: fit-content;
  text-decoration: none;
  border-bottom: 2px dotted $blue5;

  &:hover {
    border-bottom: 2px solid $blue5;
  }

  &:focus {
    border-bottom: 0;
    outline: 2px dotted $blue5;
  }

  &.cb-link-white {
    border-color: $white;

    &:hover {
      border-color: $white;
    }

    &:focus {
      border-bottom: 0;
      outline: 2px dotted $white;
    }
  }
}

.cb-no-text-decoration {
  text-decoration: none;
}

.cb-no-text-decoration-focus {
  &:focus {
    text-decoration: none;
  }
}

.cb-no-text-decoration-hover {
  &:hover {
    text-decoration: none;
  }
}

a:not(.cb-btn) {
  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      @include palette-link($value, $value);
    }
  }
}

@each $type in $cursor {
  .cb-cursor-#{$type} {
    cursor: $type;
  }
}

.cb-no-text-decoration-icon {
  text-decoration: none;

  :not(.cb-icon) {
    text-decoration: underline;
  }

  &:hover {
    :not(.cb-icon) {
      text-decoration: none;
    }
  }
}
