/*
 * CB Tooltip
 */

@import 'apricot-variable';

.cb-tooltip {
  position: absolute;
  z-index: $z-index-tooltip;

  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;

  color: $tooltip-color;
  text-align: center;

  visibility: hidden;
  opacity: 0;
  background: $tooltip-bg;
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

  transition: opacity $transition-time-l ease-in-out;

  .cb-tooltip-inner {
    padding: 12px;
    font-size: $tooltip-font-size;
  }

  &.cb-tooltip-light {
    color: $black1;
    background-color: $white;
  }

  &[data-popper-placement^='top'],
  &[data-popper-placement^='bottom'],
  &[data-popper-placement^='right'],
  &[data-popper-placement^='left'] {
    opacity: 1;
  }

  &[aria-hidden='true']:not(.cb-repetitive-label) {
    display: none;
  }
}
