/*
 * CB Card
 */

@import 'apricot-variable';
@import '../mixins/font';
@import '../mixins/breakpoint';
@import '../mixins/link';

.cb-card {
  display: flex;
  flex-direction: column;

  font-size: 1rem;
  line-height: 1.5em;

  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);

  a:not(.cb-btn) {
    @include black-link();
  }

  &.cb-white-color {
    a {
      @include white-link();
    }
  }

  &:not(.cb-card-link) {
    padding: 24px;

    .cb-text-icon-overline {
      .cb-glyph {
        &.cb-glyph-circular {
          &::after {
            background-color: $gray1;
          }
        }
      }

      .cb-icon {
        &.cb-icon-circular {
          &::after {
            background-color: $gray1;
          }
        }
      }
    }
  }

  .cb-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .cb-card-title {
    margin-bottom: 8px;

    /* 21 */
    font-size: 1.3125rem;
    font-weight: 500;

    /* 32 */
    line-height: 1.5238em;
    color: $font-color-base;
    letter-spacing: 0;

    + .cb-card-label {
      margin-top: 8px;
    }
  }

  .cb-card-desc {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $font-color-base;

    + .cb-card-label {
      margin-top: 16px;
    }
  }

  .cb-date-time-tags * {
    color: $font-color-base;
  }

  .cb-card-title-large {
    @include roboto-black();

    /* 32 */
    font-size: 2rem;

    /* 36 */
    line-height: 1.125em;
    color: $font-color-base;
  }

  .cb-card-footer {
    margin-top: auto;

    /* First */
    .cb-btn:first-child:nth-last-child(2):first-of-type,
    .cb-btn:first-child:nth-last-child(2)~.cb-btn:first-of-type,

     /* Second */
    .cb-btn:first-child:nth-last-child(2):last-of-type,
    .cb-btn:first-child:nth-last-child(2)~.cb-btn:last-of-type {
      width: calc(50% - 16px);
      min-width: min-content;
    }
  }

  .cb-card-action {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: space-between;

    margin-top: 24px;

    .cb-glyph,
    .cb-icon {
      align-self: flex-end;
      line-height: 24px;
    }

    .cb-meta-tags {
      min-width: fit-content;

      .cb-glyph,
      .cb-icon {
        align-self: auto;
      }
    }

    span:first-child:last-child {
      margin-left: auto;
    }
  }

  .cb-card-media-content {
    position: relative;

    min-height: 196px;
    margin-right: -24px;
    margin-bottom: 24px;
    margin-left: -24px;

    .cb-card-media-img {
      position: absolute;
      z-index: 1;

      width: 100%;
      height: 196px;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  /* Single Link Cards */
  &.cb-card-link {
    > a {
      padding: 24px;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;

        .cb-card-title,
        .cb-card-title-large {
          text-decoration: underline;
        }

        @for $index from 1 through 6 {
          h#{$index} {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.cb-card-title-link {
    position: relative;

    a {
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &.cb-card-media,
    &.cb-card-document {
      a {
        &::after {
          z-index: 3;
        }
      }
    }
  }

  /* ------------------------------------ CARD TYPES */

  &.cb-card-event {
    &.cb-super-event {
      .cb-card-header {
        flex-wrap: wrap;
        margin-top: -16px;
        margin-left: -24px;

        .cb-date-time-tags {
          margin-top: 16px;
          margin-left: 24px;
        }

        .cb-super-event-name {
          margin-top: 16px;
          margin-left: 24px;
          padding: 0 8px;

          font-size: $font-size-small;
          line-height: $line-height-small;
          color: $black1;
          white-space: nowrap;

          box-shadow: 0 0 1px 1px $gray3 inset;
        }
      }
    }

    .cb-card-content {
      .cb-card-action {
        flex-wrap: wrap;

        span:not(.cb-text-super-event):first-child:last-child {
          margin-left: auto;
        }

        .cb-text-super-event {
          margin-top: 24px;
          margin-right: 24px;
        }

        .cb-btn.cb-btn-primary:first-child:last-child {
          margin-left: auto;
        }
      }
    }
  }

  &.cb-card-cta {
    padding-top: 48px;
    padding-bottom: 48px;

    .cb-card-header {
      .cb-glyph-content {
        position: relative;

        width: 72px;
        height: 72px;

        font-size: 2rem;
        color: $black1;

        background-color: $white;
        border-radius: 100%;
        box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);

        &::before,
        &::after {
          top: 5px;
          left: 5px;
        }
      }
    }

    .cb-card-action {
      margin-top: 24px;
    }
  }

  &.cb-card-promo {
    border-left: 24px solid transparent;

    &.cb-no-border {
      border-left: 0;
    }

    .cb-card-header {
      flex-direction: column;
      margin-bottom: 0;

      .cb-icon + .cb-card-title {
        margin-top: 16px;
      }

      .cb-card-title {
        margin-bottom: 0;
      }
    }

    .cb-card-content {
      .cb-card-desc {
        margin-top: 16px;
      }

      .cb-card-action {
        margin-top: 0;

        .cb-meta-tags {
          margin-top: 16px;

          &.cb-text-author {
            &::before {
              content: '―';
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------ CARD BAND */

.cb-band {
  &.cb-band-hero-card {
    .cb-card {
      @include media-breakpoint-up('md', $grid-breakpoints) {
        padding: 32px;
      }

      @include media-breakpoint-up('xl', $grid-breakpoints) {
        padding: 48px;
      }

      .cb-card-action {
        align-items: center;

        .cb-btn-row {
          margin-top: -16px;
        }
      }
    }
  }
}

/* ------------------------------------ Horizontal List */
[class*='cb-card-list-horizontal'],
.cb-card-list-horizontal-24 {
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + 24px);
  margin-top: -24px;
  margin-left: -24px;

  /* mobile */
  @include media-breakpoint-down('xs', $grid-breakpoints) {
    flex-direction: column;
  }

  .cb-card {
    width: 100%;
    min-width: 336px;
    max-width: calc(25% - 24px);
    margin-top: 24px;
    margin-left: 24px;

    .cb-card-content {
      display: flex;
      flex: 1 1 auto !important;
      flex-direction: column;
    }

    /* tablet */
    @include media-breakpoint-down('sm', $grid-breakpoints) {
      min-width: auto;
      max-width: calc(50% - 24px);
    }

    /* mobile */
    @include media-breakpoint-only('xs', $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 24px);
    }
  }

  /* Flexing */
  .cb-card {
    > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .cb-card-content {
      display: flex;
      flex: 1;
      flex-direction: column;

      .cb-card-action {
        flex: 1;
        align-items: flex-end;

        .cb-glyph,
        .cb-icon {
          line-height: 24px;
        }
      }
    }
  }

  &.cb-box-shadow {
    box-shadow: none;

    .cb-card {
      box-shadow: 0 0 24px rgb(0 0 0 / 20%);
    }
  }
}

.cb-card-list-horizontal-32 {
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + 32px);
  margin-top: -32px;
  margin-left: -32px;

  .cb-card {
    width: 100%;
    min-width: 288px;
    max-width: calc(33% - 32px);
    margin-top: 32px;
    margin-left: 32px;

    /* tablet */
    @include media-breakpoint-only('sm', $grid-breakpoints) {
      max-width: calc(50% - 32px);
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 32px);
    }
  }
}

.cb-card-list-horizontal-48 {
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + 48px);
  margin-top: -48px;
  margin-left: -48px;

  .cb-card {
    width: 100%;
    min-width: 276px;
    max-width: calc(33% - 48px);
    margin-top: 48px;
    margin-left: 48px;

    /* tablet */
    @include media-breakpoint-only('sm', $grid-breakpoints) {
      max-width: calc(50% - 48px);
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 48px);
    }
  }
}

/* ------------------------------------ Vertical List */
.cb-card-list-vertical {
  .cb-card {
    max-width: 100%;

    text-align: left;

    border: 0;
    border-top: 1px solid $border-color;
    border-radius: 0;
    box-shadow: none;

    .cb-card-header {
      margin-bottom: 8px;
    }
  }
}

/* ------------------------------------ Elective */
.cb-card-list-horizontal-2,
.cb-card-list-horizontal-elective {
  @include media-breakpoint-down('xs', $grid-breakpoints) {
    margin-left: -24px;
  }

  .cb-card {
    width: calc(50% - 24px);
    min-width: auto;
    max-width: calc(50% - 24px);

    @include media-breakpoint-down('xs', $grid-breakpoints) {
      width: calc(100% + 24px);
      min-width: calc(100% + 24px);
      margin-left: 0;

      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  }
}

/* ------------------------------------ Custom Layout */
.cb-card-custom-col {
  .cb-card {
    .cb-card-content {
      display: flex;
      flex: 1 1 auto !important;
      flex-direction: column;
    }

    &.cb-card-link {
      > a {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}

/* ------------------------------------ Identity Card */
.cb-card {
  &.cb-identity-card {
    align-items: center;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 0 12px rgb(0 0 0 / 10%);

    .cb-identity-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 375px;
      min-width: 375px;

      p {
        width: 264px;
      }

      .cb-btn-primary {
        width: 200px;
        color: $white;
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        width: auto;
        min-width: auto;
      }

      @media (max-width: 380px) {
        p,
        .cb-btn-primary {
          width: auto;
        }
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .cb-card-title-link {
    a {
      &:focus {
        outline: none;
        box-shadow: none;

        &::after {
          border-radius: 2px;
          box-shadow: inset 0 0 0 2px rgb(0 95 204);
        }
      }
    }
  }
}
