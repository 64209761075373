/*
 * CB Reboot
 * 
 * Normalization of HTML elements, manually forked from Normalize.css to remove
 * styles targeting irrelevant browsers while applying new styles.
 * 
 * Normalize is licensed MIT. https://github.com/necolas/normalize.css
 */

/* Document
 * 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
 * 2. Change the default font family in all browsers.
 * 3. Correct the line height in all browsers.
 * 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
 * 5. Change the default tap highlight to be completely transparent in iOS.
*/
*,
*::before,
*::after {
  /* 1 */
  box-sizing: border-box;
}

html {
  /* 3 */
  line-height: 1.5;

  /* 4 */
  text-size-adjust: 100%;

  /* 5 */

  -webkit-tap-highlight-color: rgba($black1, 0);
}

/*
 * Body
 * 1. Remove the margin in all browsers.
 * 2. As a best practice, apply a default `background-color`.
 * 3. Set an explicit initial text-align value so that we can later use
 *    the `inherit` value on things like `<th>` elements.
 */

body {
  scroll-behavior: smooth;

  /* 1 */

  margin: 0;

  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $black1;

  /* 3 */
  text-align: left;

  /* 23 */
  background-color: $body-bg;
}

/*
 * Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
 * TODO: remove in v5
 * stylelint-disable-next-line selector-list-comma-newline-after
 */

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/*
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex='-1']:focus {
  outline: 0 !important;
}

/*
 * Content grouping
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  /* 2 */
  overflow: visible;

  /* 1 */

  box-sizing: content-box;

  /* 1 */
  height: 0;

  /* MAS */
  margin: 0;

  border-top: 1px solid $gray1;
  box-shadow: none;
}

/*
 * Typography
 * Remove top margins from headings
 * By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
 * margin for easier control within type scales as it avoids margin collapsing.
 * stylelint-disable-next-line selector-list-comma-newline-after
  */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/*
 * Reset margins on paragraphs
 * Similarly, the top margin on `<p>`s get reset. However, we also reset the
 * bottom margin to use `rem` units instead of `em`.
 */
p {
  margin: 0;

  & + p {
    margin-top: 1.5em;
  }
}

/*
 * Abbreviations
 * 1. Duplicate behavior to the data-* attribute for our tooltip plugin
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Remove the bottom border in Firefox 39-.
 * 5. Prevent the text-decoration to be skipped.
 */
abbr[title],
abbr[data-original-title] {
  /* 3 */
  cursor: help;

  /* 2 */
  text-decoration: underline;

  /* 2 */
  text-decoration: underline dotted;

  /* 5 */
  text-decoration-skip-ink: none;

  /* 4 */
  border-bottom: 0;
}

address {
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
}

b,
strong {
  /* dd the correct font weight in Chrome, Edge, and Safari */
  font-weight: $font-weight-black;
}

small {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

/*
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 1.2632em;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: $link-color;
  text-decoration: underline;
  background-color: transparent;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:active {
    color: $link-active-color;
    text-decoration: underline;
  }
}

/*
 * And undo these styles for placeholder links/named anchors (without href)
 * which have not been made explicitly keyboard-focusable (without tabindex).
 * It would be more straightforward to just use a[href] in previous block, but that
 * causes specificity issues in many other styles that are too complex to fix.
 * See https://github.com/twbs/bootstrap/issues/19402
 */

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

/* Code */
pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1rem;
}

/* pre */
pre {
  overflow: auto;
}

/* Figures */
figure {
  margin: 0;
}

/* Images and content */
img {
  vertical-align: middle;

  /* Remove the border on images inside links in IE 10-. */
  border-style: none;
}

svg {
  /* Workaround for the SVG overflow bug in IE10/11 is still required.
   * See https://github.com/twbs/bootstrap/issues/26878
   */
  overflow: hidden;
  vertical-align: middle;
}

/* Tables */
table {
  /* Prevent double borders */
  border-collapse: collapse;
}

th {
  /* Matches default `<td>` alignment by inheriting from the `<body>`, or the
   * closest parent with a set `text-align`.
   */
  text-align: inherit;
}

/* Forms */
label {
  /* Allow labels to use `margin` for spacing. */
  display: inline-flex;
  justify-content: center;
}

/*
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  /* stylelint-disable-next-line property-blacklist */
  border-radius: 0;
  box-shadow: none;
}

input,
button,
select,
optgroup,
textarea {
  /* Remove the margin in Firefox and Safari */
  margin: 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5em;
}

button,
input {
  /* Show the overflow in Edge */
  overflow: visible;
}

button,
select {
  /* Remove the inheritance of text transform in Firefox */
  text-transform: none;
}

/*
 * Remove the inheritance of word-wrap in Safari.
 * Details at https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal;
}

/*
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
/* 1 */
[type="reset"],
[type="submit"] {
  /* 2 */
  -webkit-appearance: button;
  appearance: button;
}

/* Opinionated: add "hand" cursor to non-disabled button elements. */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  /* 2 */
  -webkit-appearance: button;
  appearance: button;

  &:not(:disabled) {
    cursor: pointer;
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
}

/* Remove inner border and padding from Firefox, but don't restore the outline like Normalize. */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  /* 1. Add the correct box sizing in IE 10- */
  box-sizing: border-box;

  /* 2. Remove the padding in IE 10- */
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  /* Remove the default appearance of temporal inputs to avoid a Mobile Safari
   * bug where setting a custom line-height prevents text from being vertically
   * centered within the input.
   * See https://bugs.webkit.org/show_bug.cgi?id=139848
   * and https://github.com/twbs/bootstrap/issues/11266
   */
  -webkit-appearance: listbox;
  appearance: listbox;
}

textarea {
  /* Textareas should really only resize vertically so they don't break their (horizontal) containers. */
  resize: vertical;

  /* Remove the default vertical scrollbar in IE. */
  overflow: auto;
}

fieldset {
  /* Browsers set a default `min-width: min-content;` on fieldsets,
   * unlike e.g. `<div>`s, which have `min-width: 0;` by default.
   * So we reset that to ensure fieldsets behave more like a standard block element.
   * See https://github.com/twbs/bootstrap/issues/12359
   * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
   */
  min-width: 0;
  margin: 0;

  /* Reset the default outline behavior of fieldsets so they don't affect page layout. */
  padding: 0;
  border: 0;
}

progress {
  /* Add the correct vertical alignment in Chrome, Firefox, and Opera. */
  vertical-align: baseline;
}

/* Correct the cursor style of increment and decrement buttons in Chrome. */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: none;
  appearance: none;

  /* This overrides the extra rounded corners on search inputs in iOS so that our
   * `.form-control` class can properly style them. Note that this cannot simply
   * be added to `.form-control` as it's not specific enough. For details, see
   * https://github.com/twbs/bootstrap/issues/11586.
   */

  /* 2. Correct the outline style in Safari. */
  outline-offset: -2px;
}

/* Remove the inner padding in Chrome and Safari on macOS. */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

/* 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 2 */
  font: inherit;

  /* 1 */
  -webkit-appearance: button;
  appearance: button;
}

/* Correct element displays */
output {
  display: inline-block;
}

summary {
  cursor: pointer;

  /* Add the correct display in all browsers */
  display: list-item;
}

template {
  /* Add the correct display in IE */
  display: none;
}

/* Always hide an element with the `hidden` HTML attribute (from PureCSS).
 * Needed for proper display in IE 10-.
 */
[hidden] {
  display: none !important;
}
