/*
 * CB Back To Top
 */

@import 'apricot-variable';
@import '../mixins/breakpoint';

.cb-back-to-top {
  position: fixed;
  right: 24px;
  bottom: 24px;
  display: none;

  &.cb-back-to-top-show {
    display: block;
  }

  &.cb-back-to-top-hide {
    display: none;
  }
}
