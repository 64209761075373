/*
 * CB Text width Media
 */

@import 'apricot-variable';
@import '../mixins/font';
@import '../mixins/breakpoint';
@import '../mixins/typography';
@import '../mixins/link';

/* ------------------------------------ TEXT AND ICON */

/* medium and basic */
.cb-text-icon-left {
  font-size: $font-size-base;
  line-height: $line-height-base;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}

.cb-text-icon-right {
  font-size: $font-size-base;
  line-height: $line-height-base;

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;
  }
}

/* small */
.cb-text-icon-left-sm,
.cb-text-icon-left-14 {
  /* 14 */
  font-size: $font-size-small !important;

  /* 16 */
  line-height: $line-height-small-16 !important;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}

.cb-text-icon-right-sm,
.cb-text-icon-right-14 {
  /* 14 */
  font-size: $font-size-small !important;

  /* 16 */
  line-height: $line-height-small-16 !important;

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;
  }
}

/* xs */
.cb-text-icon-left-xs,
.cb-text-icon-left-12 {
  /* 12 */
  font-size: 0.75rem !important;

  /* 16 */
  line-height: 1.3333em !important;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}

.cb-text-icon-right-xs,
.cb-text-icon-right-12 {
  /* 12 */
  font-size: 0.75rem !important;

  /* 16 */
  line-height: 1.3333em !important;

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;

    /* 10 */
    font-size: 0.625rem !important;

    /* 16 */
    line-height: 1.6em !important;
  }
}

a {
  &.cb-text-icon-left,
  &.cb-text-icon-right,
  &.cb-text-icon-left-md,
  &.cb-text-icon-right-md,
  &.cb-text-icon-left-sm,
  &.cb-text-icon-right-sm,
  &.cb-text-icon-left-xs,
  &.cb-text-icon-right-xs {
    text-decoration: none;

    &:hover,
    &.cb-hover,
    &:active,
    &.cb-active {
      text-decoration: none !important;

      span:not(.cb-glyph),
      span:not(.cb-icon) {
        text-decoration: underline;
      }
    }
  }
}

/* ------------------------------------ OVERLINE */
.cb-text-icon-overline {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
    color: $blue5;
  }

  p,
  h4,
  h5 {
    font-size: $font-size-small;
    line-height: $line-height-small;
    text-transform: uppercase;

    @include roboto-medium();
  }
}

/* ------------------------------------ BYLINE */
.cb-byline {
  display: flex;
  flex-wrap: nowrap;

  > a {
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;

    @include black-link();

    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }

  .cb-glyph-avatar,
  .cb-icon-avatar {
    min-width: 2em;

    + .cb-byline-content {
      margin-left: 8px;
    }
  }

  .cb-byline-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      /* 14 */
      font-size: $font-size-small;

      /* 16 */
      line-height: 1.1429em;
    }
  }

  @include media-breakpoint-up('md', $grid-breakpoints) {
    .cb-glyph-avatar:not(.cb-glyph-sm),
    .cb-icon-avatar:not(.cb-glyph-sm) {
      min-width: 3em;

      + .cb-byline-content {
        margin-left: 16px;
      }
    }

    .cb-byline-content {
      .cb-meta-tags {
        @include cb-text-meta-large();

        /* 21 */
        line-height: 1.5em;
      }

      p {
        font-size: 1rem;
        line-height: 1.5em;
      }
    }
  }
}

/* ------------------------------------ META TAG */
.cb-meta-tags,
.cb-meta-tags-large {
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;

  text-transform: capitalize;
  list-style: none;

  @include cb-text-meta();

  & {
    line-height: 2em;
  }

  p {
    @include cb-text-meta();

    line-height: 2em;
  }

  li {
    display: flex;
    align-items: center;

    &::after {
      content: '\007C';
      display: inline-flex;
      padding: 0 12px;
      color: $gray3;
    }

    &:last-of-type {
      &::after {
        content: '';
        padding: 0;
      }
    }
  }

  a {
    text-decoration: none;

    @include black-link();

    &:hover {
      text-decoration: underline;
    }
  }
}

.cb-meta-tags-large {
  @include cb-text-meta-large();

  p {
    @include cb-text-meta-large();
  }
}

/* ------------------------------------ LABEL TAG */
.cb-card-label {
  display: inline-flex;

  max-width: fit-content;
  margin: 0;
  padding: 3px;

  font-size: $font-size-x-small;
  line-height: 1em;
  color: $gray1;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  background-color: $white;
  border: 1px solid $gray4;
  border-radius: 4px;
}

/* ------------------------------------ DATE and TIME TAG */
.cb-date-time-tags {
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  margin-top: auto;
  padding: 0;

  text-transform: capitalize;

  p {
    display: inline-flex;
    font-size: 14px;
    line-height: 24px;

    & + p {
      margin: 0;
    }

    &:first-of-type {
      position: relative;
      margin-right: 32px;

      &::after {
        content: ' ';

        position: absolute;
        right: -16px;

        width: 1px;
        height: 24px;

        background-color: $gray4;
      }
    }

    &:first-of-type:last-of-type {
      &::after {
        width: 0;
        margin: 0;
        background-color: transparent;
      }
    }

    &.cb-event-stamp {
      padding: 0 8px;
      background-color: $gray4;
    }
  }
}

/* ------------------------------------ TEXT and ICON */
.cb-text-icon-block {
  .flex-shrink-0 {
    line-height: 0;
  }
}

.cb-score-metric {
  max-width: fit-content;
  text-align: center;

  .cb-score-label {
    padding: 0 24px 8px;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-bottom: 1px solid $black1;
  }

  .cb-score-value {
    padding: 8px 24px 0;
    font-size: 4.5rem;
    line-height: 1em;
  }
}

/* ------------------------------------ FIX ICON */
.cb-fixed-icon-top-left {
  position: relative;

  [class^='cb-glyph-content'],
  [class^='cb-icon'],
  [class^='cb-ilus'] {
    position: absolute;
    top: 24px;
    left: 24px;
  }
}

.cb-fixed-icon-top-right {
  position: relative;

  [class^='cb-glyph-content'],
  [class^='cb-icon'],
  [class^='cb-ilus'] {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

/* ------------------------------------ VIDEO OVERLAY EFFECT */
.cb-video-block {
  position: relative;

  .cb-video-img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .cb-video-effect {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  .cb-video-effect-col {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}

.cb-video-block-col {
  position: relative;

  .cb-video-effect {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100% - 24px);
    height: 100%;
  }
}
