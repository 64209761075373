/*
 * CB Illustrations Base
 */

@import 'apricot-variable';
@import '../mixins/breakpoint';
@import '../mixins/illustrations';

.cb-ilus {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;

  @include ilus-size();

  &::after {
    content: '';
    position: absolute;
    display: block;
    background-repeat: no-repeat;
  }
}

/* ------------------------------------ SHADOW */
.cb-ilus {
  &.cb-ilus-shadow {
    &::before {
      content: '';

      display: block;

      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-position: 50% calc(100% - 8px);
      background-size: 32%;
    }

    &::after {
      top: calc(50% - (calc(100% - 16px) / 2));
    }
  }

  /* ------------------------------------ CIRCULAR */

  &.cb-ilus-circular {
    background-repeat: no-repeat;
    background-position: calc(50% - (calc(100% - 16px) / 2)) calc(50% - (calc(100% - 16px) / 2));
    background-size: calc(100% - 16px) calc(100% - 16px);

    &::after {
      top: 0 !important;
    }

    &::before {
      background-position: 50% calc(100% - 16px);
    }
  }
}

/* ------------------------------------ CUSTOM CIRCULAR */
.cb-ilus-custom-circular {
  border-radius: 50%;

  &.cb-ilus {
    &.cb-ilus-96 {
      @include ilus-size(40px, 32px);
    }

    &.cb-ilus-72 {
      @include ilus-size(32px, 24px);
    }

    &.cb-ilus-64 {
      @include ilus-size(28px, 20px);
    }
  }
}

/* ------------------------------------ SIZE PATTERN */
$ilus-size: (64, 72, 96);

@each $size in $ilus-size {
  .cb-ilus {
    &.cb-ilus-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $ilus-size {
      .cb-ilus-#{$breakpoint}-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $ilus-size {
      .cb-ilus-#{$breakpoint}-up-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $ilus-size {
      .cb-ilus-#{$breakpoint}-down-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }
}
