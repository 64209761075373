/*
 * CB Local Navigation
 */

@import 'apricot-variable';
@import '../mixins/breakpoint';
@import '../mixins/link';

@mixin mobile-layout() {
  .cb-mobile-panel {
    position: fixed;
    z-index: 901;
    top: 0;
    left: calc(-100% - 40px);

    display: block;

    width: 100vw;
    height: 100vh;
    padding: 16px 0 24px;

    background-color: $white;
    box-shadow: 0 0 24px rgb(0 0 0 / 20%);

    transition: all 0.25s;
    transition-delay: 0s;

    .cb-panel-navigation {
      display: flex;
      padding: 0 16px 16px 24px;
      border-bottom: 1px solid $gray4;

      .cb-close-link,
      .cb-back-link {
        line-height: 16px;
        text-decoration: none;

        @include black-link();

        &:hover,
        &:focus,
        &:visited {
          text-decoration: none;
        }
      }

      .cb-back-link {
        font-size: $font-size-small;
        font-weight: 500;
        line-height: 16px;
      }

      .cb-close-link {
        margin-left: auto;
      }
    }

    .cb-menu-list-vertical {
      overflow-y: auto;
      height: calc(100vh - 48px);
    }

    .cb-menu-link {
      padding-right: 16px;
    }

    .cb-nested-navigation {
      .cb-menu-link {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
      }
    }

    &.cb-panel-show {
      left: 0;
      transition: all 0.25s ease-in-out;
    }
  }
}

.cb-local-navigation {
  &.cb-sticky-local-navigation {
    position: sticky;
    z-index: 901;
    top: 0;
    left: 0;

    width: 100%;
  }

  > .cb-desktop-navigation {
    > .row {
      @include media-breakpoint-only('xs', $grid-breakpoints) {
        margin-right: -24px;

        .col-xs {
          &.cb-nav-container {
            padding-right: 0;
          }
        }
      }
    }

    .cb-nav-container {
      display: flex;
      justify-content: space-between;

      .cb-site-name {
        display: inline-flex;
        justify-content: flex-start;
        min-width: fit-content;

        @include media-breakpoint-only('xs', $grid-breakpoints) {
          align-items: center;
          height: 48px;
        }

        a {
          position: relative;

          padding: 36px 24px 12px 0;

          font-size: 1.4375rem;
          font-weight: 500;
          line-height: 1.0435em;
          text-decoration: none;
          white-space: nowrap;

          @include black-link();

          &:hover,
          &:focus,
          &:visited {
            text-decoration: underline;
          }

          .cb-icon {
            position: absolute;
            bottom: 27px;
            font-size: 7px;

            &.cb-trademark {
              bottom: 29px;
            }
          }

          @include media-breakpoint-only('xs', $grid-breakpoints) {
            padding: 0;
            font-size: $font-size-md;
            line-height: $line-height-md;

            .cb-icon {
              top: 3px;
              right: -8px;

              &.cb-trademark {
                top: 1px;
              }
            }
          }
        }
      }

      .cb-nav-items {
        display: inline-flex;
        flex-basis: 100%;
        justify-content: flex-end;
        height: 72px;

        .cb-menu-list-horizontal {
          width: 100%;

          > ul {
            justify-content: flex-end;
            width: 100%;

            > li {
              display: flex;
              justify-content: flex-end;

              &.cb-promotional {
                &.cb-last-of-type {
                  margin-right: auto;
                }
              }

              .cb-menu-link {
                white-space: nowrap;
              }

              .cb-dropdown {
                > a {
                  &:focus {
                    outline: auto;
                    outline-color: $outline-color;
                  }
                }

                .cb-dropdown-toggle {
                  height: 100%;
                  white-space: nowrap;

                  &:hover,
                  &.cb-hover {
                    background-color: $gray5;
                  }
                }

                .cb-dropdown-menu {
                  ul {
                    flex-direction: column;

                    > li {
                      > a {
                        &:hover {
                          box-shadow: inset 2px 0 0 0 $black1;
                        }

                        &.cb-selected {
                          box-shadow: inset 2px 0 0 0 $black1;
                        }
                      }
                    }

                    .cb-nested-menu {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cb-mobile-trigger {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        min-width: fit-content;
        padding: 16px;

        border-left: 1px solid $gray4;

        a {
          @include black-link();

          &:hover,
          &:focus,
          &:visited {
            text-decoration: none;
          }
        }
      }
    }
  }

  > .cb-mobile-navigation {
    .cb-mobile-panel {
      display: none;
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      @include mobile-layout();
    }
  }

  /* when we activate mobile layout for tablet */
  &.cb-tablet-local-navigation {
    @include media-breakpoint-down('sm', $grid-breakpoints) {
      > .cb-desktop-navigation {
        max-width: 100%;

        > .row {
          margin-right: -24px;

          .col-xs {
            &.cb-nav-container {
              padding-right: 0;
            }
          }
        }

        .cb-nav-container {
          .cb-site-name {
            align-items: center;
            height: 48px;

            a {
              padding: 0;
              font-size: $font-size-md;
              line-height: $line-height-md;
            }
          }
        }
      }

      > .cb-mobile-navigation {
        @include mobile-layout();
      }
    }
  }

  .cb-menu-link {
    color: $black1 !important;

    &:visited {
      color: $black1 !important;
    }
  }
}

.cb-sticky-local-navigation {
  .cb-local-navigation {
    > .cb-desktop-navigation {
      position: fixed;
      z-index: 901;
      top: 0;
      left: 0;
    }
  }
}

.cb-local-navigation-lock {
  overflow-y: hidden;
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .cb-local-navigation {
    > .container-fluid {
      .cb-menu-list-horizontal {
        > ul {
          > li {
            .cb-dropdown {
              .cb-dropdown-toggle {
                &:focus,
                &.cb-focus {
                  text-decoration: underline !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
