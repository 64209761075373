/*
 * CB CUSTOM CSS
 */

@import 'apricot-variable';

/* ------------------------------------ Custom Styles for Projects */

$tabs-height: 32px;

.k12cloudPortal-toggles-btn {
  height: $tabs-height;

  .cb-toggles-btn {
    label {
      width: 120px;
      height: $tabs-height;

      input[type='radio'] {
        + span {
          width: 120px !important;
          height: $tabs-height !important;
          font-weight: 400 !important;
        }

        &:checked {
          & + span {
            position: relative;
            padding-left: 8px;
            color: $white !important;
            background-color: $gray1 !important;

            &::before {
              content: '\e953';

              position: absolute;
              left: 8px;

              display: inline-block;

              font-family: $icons-font-family-group-a;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
              line-height: 1;
              color: $white;
              text-transform: none;
            }
          }
        }
      }

      &:first-of-type input[type='radio'] + span:not(.cb-glyph):not(.cb-icon) {
        border-bottom-left-radius: 0;
      }

      &:last-of-type input[type='radio'] + span:not(.cb-glyph):not(.cb-icon) {
        border-bottom-right-radius: 0;
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .k12cloudPortal-toggles-btn {
    .cb-toggles-btn {
      input[type='radio'] {
        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            position: relative;
            border-left: 1px solid $white;
            box-shadow: none;

            &::after {
              content: '';

              position: absolute;
              top: -2px;
              right: -1px;

              width: calc(100% + 4px);
              height: calc(100% + 3px);

              border: 2px solid $blue5;
              box-shadow: inset 0 0 0 1px $white;
            }
          }
        }
      }

      label {
        &:first-of-type {
          input[type='radio'] {
            &:focus {
              & + span:not(.cb-glyph):not(.cb-icon) {
                &::after {
                  border-top-left-radius: 8px;
                }
              }
            }
          }
        }

        &:last-of-type {
          input[type='radio'] {
            &:focus {
              & + span:not(.cb-glyph):not(.cb-icon) {
                &::after {
                  border-top-right-radius: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
