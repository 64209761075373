/*
 * CB Event Timeline Mixins
 */

/* ------------------------------------  VERTICAL */
@mixin event-cards-vertical() {
  margin-left: 8px;

  @include media-breakpoint-only('xs', $grid-breakpoints) {
    margin-left: 0;
  }

  ol,
  .row {
    flex-direction: column;

    .cb-card {
      position: relative;

      margin-left: 24px;
      padding: 24px;

      background-color: $white;
      border-radius: 16px;
      box-shadow: 0 0 12px rgb(0 0 0 / 10%);

      &::before {
        content: '';

        position: absolute;
        top: -24px;
        left: -24px;

        display: block;

        width: 2px;
        height: calc(100% + 24px);

        background-color: $gray4;
      }

      + .cb-card {
        margin-top: 24px;
      }

      &:first-of-type {
        &::before {
          top: 32px;
          height: calc(100% - 6px);
        }
      }

      &.cb-event-year-stamp {
        margin-top: 48px;

        .cb-event-year {
          position: absolute;
          top: -48px;
          left: 0;

          font-size: 1.5rem;
          font-weight: 500;
          line-height: 32px;
        }
      }

      + .cb-card {
        &.cb-event-year-stamp {
          margin-top: 92px;

          &::before {
            top: -92px;
            height: calc(100% + 92px);
          }
        }
      }

      &:last-of-type {
        padding-bottom: 24px;

        &::before {
          height: 64px;
        }

        &.cb-event-year-stamp {
          &::before {
            top: -92px;
            height: 120px;
          }
        }
      }

      .cb-card-header {
        .cb-date-time-tags {
          position: relative;
          margin-bottom: 0;

          &::before {
            content: '';

            position: absolute;
            top: 4px;
            left: -55px;

            display: block;

            width: 1rem;
            height: 1rem;

            background-color: $gray4;
            border-radius: 50%;
          }

          p {
            text-transform: uppercase;
          }
        }
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        margin-top: 48px !important;
        margin-left: 0;

        &::before {
          content: '';

          position: absolute;
          top: -48px !important;
          left: calc(50% - 1px);

          display: block;

          width: 2px;
          height: 48px !important;

          background-color: $gray4;
        }

        &::after {
          content: '';

          position: absolute;
          top: -32px;
          left: calc(50% - 8px);

          display: block;

          width: 1rem;
          height: 1rem;

          background-color: $gray4;
          border-radius: 50%;
        }

        &:first-of-type {
          &::before,
          &::after {
            display: none;
          }

          &:not(.cb-event-year-stamp) {
            margin-top: 0 !important;
          }
        }

        + .cb-card {
          &.cb-event-year-stamp {
            margin-top: 88px !important;

            &::before {
              top: -88px !important;
              height: 88px !important;
            }

            &::after {
              top: -52px;
            }
          }
        }

        .cb-card-header {
          .cb-date-time-tags {
            &::before {
              display: none;
            }
          }

          .cb-card-label,
          .cb-btn-tag {
            width: -moz-fit-content;
            max-width: -moz-fit-content;
          }
        }
      }
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      &.cb-gutterh-24 {
        margin-left: 0;
      }
    }
  }

  /* grid width, horizontal - xs */
  @include media-breakpoint-only('xs', $grid-breakpoints) {
    .row {
      div[class*='col-'] {
        margin-top: 48px !important;

        .cb-card {
          &::before {
            content: '';

            position: absolute;
            top: -48px !important;
            left: calc(50% - 1px);

            display: block;

            width: 2px;
            height: 48px !important;

            background-color: $gray4;
          }

          &::after {
            content: '';

            position: absolute;
            top: -32px;
            left: calc(50% - 8px);

            display: block;

            width: 1rem;
            height: 1rem;

            background-color: $gray4;
            border-radius: 50%;
          }
        }

        &:first-of-type {
          .cb-card {
            &::before,
            &::after {
              display: none;
            }

            &:not(.cb-event-year-stamp) {
              margin-top: 0 !important;
            }
          }
        }

        + div[class*='col-'] {
          .cb-card {
            &.cb-event-year-stamp {
              margin-top: 40px !important;

              &::before {
                top: -88px !important;
                height: 88px !important;
              }

              &::after {
                top: -52px;
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------  HORIZONTAL */
@mixin event-cards-horizontal() {
  @include media-breakpoint-up('sm', $grid-breakpoints) {
    @include event-cards-horizontal-line();
  }

  @include media-breakpoint-only('xs', $grid-breakpoints) {
    @include event-cards-vertical();
  }
}

@mixin event-cards-horizontal-line() {
  overflow-x: auto;
  flex-wrap: nowrap;

  ol,
  .row {
    flex-wrap: nowrap;
    padding: 8px;

    .cb-card {
      position: relative;
      width: 320px;
      min-width: 320px;
      margin-top: 72px;

      &::before {
        content: '';

        position: absolute;
        top: -24px;
        left: 24px;

        display: block;

        width: calc(100% + 24px);
        height: 2px;

        background-color: $gray4;
      }

      &::after {
        content: '';

        position: absolute;
        top: -31px;
        left: 24px;

        display: block;

        width: 1rem;
        height: 1rem;

        background-color: $gray4;
        border-radius: 50%;
      }

      &:last-of-type {
        &::before {
          width: 0;
        }
      }

      .cb-card-header {
        flex-flow: column-reverse;

        .cb-card-label,
        .cb-btn-tag {
          display: inline-flex;
          max-width: fit-content;
          margin-bottom: 16px;
        }

        .cb-date-time-tags {
          position: relative;
          margin-bottom: 0;

          p {
            text-transform: uppercase;
          }
        }
      }

      .cb-card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .cb-card-title {
          overflow: hidden;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;

          max-width: 100%;

          text-overflow: ellipsis;

          + .cb-card-action {
            margin-top: 8px;
          }
        }
      }

      &.cb-event-year-stamp {
        .cb-event-year {
          position: absolute;
          top: -72px;
          left: 0;

          font-size: 1.5rem;
          font-weight: 500;
          line-height: 32px;
        }
      }
    }

    &.cb-gutterh-24 {
      margin-left: 0;

      .cb-card {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .cb-has-label {
    .cb-card-content {
      .cb-card-title {
        -webkit-line-clamp: 2 !important;
      }
    }
  }

  .row {
    div[class*='col-'] {
      .cb-card {
        &::before {
          width: calc(100% + 24px);
        }
      }

      &:last-of-type {
        .cb-card {
          &::before {
            width: 0 !important;
          }
        }
      }
    }
  }
}
